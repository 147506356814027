import React from 'react';
import {Global} from '@emotion/react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {grey} from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {FlightTakeoff} from "@mui/icons-material";
import {TravelCard} from "../travelCard/travelCard";

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
      theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

export const  Slider = (props) =>  {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = typeof window !== "undefined" && window.document.body;

  return (
      <Root>
        <CssBaseline />
        <Global
            styles={{
              '.MuiDrawer-root > .MuiPaper-root': {
                height: `calc(80% - ${drawerBleeding}px)`,
                overflow: 'visible',
              },
            }}
        />
        <Box sx={{ textAlign: 'center'}}>
          <Button
              variant={"contained"}
              color={"mint"}
              onClick={toggleDrawer(true)}
              sx={{borderRadius: "10px 0 10px 0", padding:"10px 25px"}}
              startIcon={<FlightTakeoff/>}
          >Itiniéraire de vol</Button>
        </Box>
        <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
        >
          <StyledBox
              sx={{
                position: 'absolute',
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
              }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>51 results</Typography>
          </StyledBox>
          <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: '100%',
                overflow: 'auto',
              }}
          >
            {/*<Skeleton variant="rectangular" height="100%"/>*/}
           <TravelCard/>
          </StyledBox>
        </SwipeableDrawer>
      </Root>
  );
}

