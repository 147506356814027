import React, {useEffect, useState} from "react";
import {dsv} from "d3-fetch";
import {scaleLinear} from "d3-scale";
import {ComposableMap, Geographies, Geography,} from "react-simple-maps";
import {HoneyMoonPageStyle} from "./style";
import {Fade, Stack, useMediaQuery} from "@mui/material";
import {Slider} from "./organism/slider/slider";
import Typography from "@mui/material/Typography";
import {FrostedGlass} from "./organism/frostedGlass/frostedGlass";

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const colorScale = scaleLinear()
    .domain([2, 4, 6, 8])
    .range(["#EEFAF2", "#C6E6D5", "#A7CEB9", "#87B89E"]);

const MapChart = () => {
  const [data, setData] = useState([]);
  const desktop = useMediaQuery('(min-width:769px)');

  useEffect(() => {
    dsv(";",`/countries.csv`).then((data) => {
      setData(data);
    });
  }, []);

  return (
      <HoneyMoonPageStyle>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} sx={{height:"100%", pt:4}}>
          <div>
              {/*<Sphere stroke="#fff" strokeWidth={0.5}/>*/}
              <Fade in={data.length > 0} appear={true}>
                <div>
                  <ComposableMap
                      className={"map"}
                      projectionConfig={{
                        rotate: [-10, 0, 0],
                        scale: 147
                      }}
                  >
                    <Geographies geography={geoUrl}>
                      {({ geographies }) =>
                          geographies.map((geo) => {
                            const d = data.find((s) => s.ISO3 === geo.id);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={d ? colorScale(d["Index"]) : "#F5F4F6"}
                                />
                            );
                          })
                      }
                    </Geographies>
                  </ComposableMap>
                </div>
              </Fade>
          </div>
          {desktop ?
              <FrostedGlass/> :
              <>
                <Typography textAlign={"center"} variant={"p"} sx={{ p: 2, color: '#87B89E' }}>Aidez nous à nous envoler pour l'une de ces magnifiques destinations</Typography>
                <Slider/>
              </>
          }
        </Stack>
      </HoneyMoonPageStyle>
  );
};

export default MapChart;
