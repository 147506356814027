import {Box, Collapse, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {FlightTakeoff} from "@mui/icons-material";
import React, {useState} from "react";
import {TravelCard} from "../travelCard/travelCard";

export const FrostedGlass = () => {
  const [open, setOpen] = useState(false);
  return (
      <Box
          sx={{
            position:"absolute",
            top:100,
            width: 1000,
            maxWidth:"90%",
            margin:"auto",
            minHeight: 300,
            padding:2,
            bgcolor: "rgba(255,255,255,.35)",
            backdropFilter: "blur(5px)"
          }}
      >
        <Stack>
          <Typography mt={5} mb={5} textAlign={"center"} variant={"h3"} color={"#87B89E"}>Aidez nous à nous envoler pour l'une de ces magnifiques destinations</Typography>
          <Box sx={{ textAlign: 'center'}}>
            <Button
                variant={"contained"}
                color={"mint"}
                onClick={() => setOpen(!open)}
                sx={{borderRadius: "10px 0 10px 0", padding:"10px 25px", fontSize:20}}
                startIcon={<FlightTakeoff sx={{fontSize:"20px !important", pr:2}}/>}
            >Itiniéraire de vol</Button>
          </Box>
          <Collapse in={open} sx={{padding:"50px 0 25px 0"}}>
            <TravelCard/>
          </Collapse>
        </Stack>
      </Box>
  )
}