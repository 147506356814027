import {Card, CardContent, CardHeader, CardMedia, Grid, Stack, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {AirplanemodeActive} from "@mui/icons-material";
import React from "react";

export const TravelCard = () => {
  const desktop = useMediaQuery('(min-width:769px)');

  return (
      <Stack direction={desktop ? "row" : "column"} spacing={2}>
        <Card sx={{display:"flex", mb:2, flexFlow:"row", flexWrap:"wrap"}}>
          <CardHeader
              title="Virement bancaire"
              titleTypographyProps={{
                color: "text.secondary",
                fontWeight:700,
                textAlign:"center",
                //borderBottom: "1px solid #fff",
                pb:2,
              }}
              subheader={
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                  <Stack>
                    <Typography fontSize={12} color={"accent.main"} textAlign={"center"}>Passagers</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Stéphanie Cange</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Eric Chautems</Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12} color={"accent.main"} textAlign={"center"}>Montant</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Le montant</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>que vous souhaitez</Typography>
                  </Stack>
                </Stack>
              }
              sx={{display:"flex", width:"100%", bgcolor: "mint.main"}}
          />
          <CardContent sx={{display:"flex"}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction={"row"} className="flight-row" width={"100%"} justifyContent={"space-between"} style={{position:"relative"}}>
                  <Box sx={{width:"100%", height:"100%", position:"absolute", display:"flex", alignItems:"center"}}>
                    <div style={{border:"1px solid #C6E6D5", width:"100%"}}/>
                  </Box>
                  <Stack className="flight-from" bgcolor={"#fff"} sx={{zIndex:1}}>
                    <Typography color="mint.main" fontWeight={700} className="from-code" textAlign={"center"}>GVA</Typography>
                    <Typography color="mint.main" fontSize={12} className="from-code" textAlign={"center"}>Genève, Suisse</Typography>
                  </Stack>
                  <Box className="plane" width={50} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{zIndex:1, bgcolor:"#fff"}}>
                    <AirplanemodeActive color="mint" height={"100%"} sx={{transform: "rotate(90deg)"}}/>
                  </Box>
                  <Stack className="flight-to" bgcolor={"#fff"} sx={{zIndex:1}}>
                    <Typography color="mint.main" fontWeight={700} className="from-code" textAlign={"center"}>???</Typography>
                    <Typography color="mint.main" fontSize={12} className="from-code" textAlign={"center"} >Surprise</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} display={"flex"} alignItems={"center"}>
                <CardMedia
                    sx={{width:100, height:100}}
                    image="/QR_bank.png"
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={12} color={"text.accent"}>
                    <Typography color="forest.main">Adresse:</Typography>
                  </Grid>
                  <Grid item xs={12} fontStyle={"italic"}>
                    <Typography color="mint.main" fontSize={14}>Chemin de Crêt-Mouton 26
                      1091 Bourg-en-Lavaux</Typography>
                  </Grid>
                  <Grid item xs={12} color={"text.accent"}>
                    <Typography color="forest.main">IBAN:</Typography>
                  </Grid>
                  <Grid item xs={12} fontStyle={"italic"}>
                    <Typography color="mint.main" fontSize={14}>CH30 0900 0000 1582 1310 5</Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        <Card sx={{display:"flex", mb:"16px !important", flexFlow:"row", flexWrap:"wrap"}}>
          <CardHeader
              title="Paiement Twint"
              titleTypographyProps={{
                color: "text.secondary",
                fontWeight:700,
                textAlign:"center",
                //borderBottom: "1px solid #fff",
                pb:2,
              }}
              subheader={
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                  <Stack>
                    <Typography fontSize={12} color={"accent.main"} textAlign={"center"}>Passagers</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Stéphanie Cange</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Eric Chautems</Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12} color={"accent.main"} textAlign={"center"}>Montant</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>Le montant</Typography>
                    <Typography fontSize={14} fontStyle={"italic"} color="lightMint.main" textAlign={"center"}>que vous souhaitez</Typography>
                  </Stack>
                </Stack>
              }
              sx={{display:"flex", width:"100%", bgcolor: "mint.main"}}
          />
          <CardContent sx={{display:"flex"}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction={"row"} className="flight-row" width={"100%"} justifyContent={"space-between"} style={{position:"relative"}}>
                  <Box sx={{width:"100%", height:"100%", position:"absolute", display:"flex", alignItems:"center"}}>
                    <div style={{border:"1px solid #C6E6D5", width:"100%"}}/>
                  </Box>
                  <Stack className="flight-from" bgcolor={"#fff"} sx={{zIndex:1}}>
                    <Typography color="mint.main" fontWeight={700} className="from-code" textAlign={"center"}>GVA</Typography>
                    <Typography color="mint.main" fontSize={12} className="from-code" textAlign={"center"}>Genève, Suisse</Typography>
                  </Stack>
                  <Box className="plane" width={50} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{zIndex:1, bgcolor:"#fff"}}>
                    <AirplanemodeActive color="mint" height={"100%"} sx={{transform: "rotate(90deg)"}}/>
                  </Box>
                  <Stack className="flight-to" bgcolor={"#fff"} sx={{zIndex:1}}>
                    <Typography color="mint.main" fontWeight={700} className="from-code" textAlign={"center"}>???</Typography>
                    <Typography color="mint.main" fontSize={12} className="from-code" textAlign={"center"} >Surprise</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} display={"flex"} alignItems={"center"}>
                <CardMedia
                    sx={{width:100, height:100}}
                    image="/TWINT.svg"
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={12} color={"text.accent"}>
                    <Typography color="forest.main">Numéro:</Typography>
                  </Grid>
                  <Grid item xs={12} fontStyle={"italic"}>
                    <Typography color="mint.main" fontSize={14}>Stéphanie Cange 0787202548 ou</Typography>
                  </Grid>
                  <Grid item xs={12} fontStyle={"italic"}>
                    <Typography color="mint.main" fontSize={14}>Eric Chautems 0792015495</Typography>
                  </Grid>
                  <Grid item xs={12} color={"text.accent"}>
                    <Typography color="forest.main">Commentaire:</Typography>
                  </Grid>
                  <Grid item xs={12} fontStyle={"italic"}>
                    <Typography color="mint.main" fontSize={14}>Votre prénom et nom + "concerne: mariage"</Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Stack>
  )
}