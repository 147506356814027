import styled from "styled-components";
import "../../global/main.css"
import {mediaQuery} from "../../global/helpers/styleHelpers";

export const HoneyMoonPageStyle = styled.div`
  min-height:100vh;
  background: #E3EFE7;
  .map {
    width:2000px;
    height:auto;
    max-height: 100vh;
    max-width:130vw;
    @media (min-width: ${mediaQuery("md")}) {
      margin-top:150px
    }
  }
`;

